import { createSlice } from "@reduxjs/toolkit";
import { apiCallBegan } from "../api";
import initialState from "../initialState";
import {
  USER_URL as url,
  USER_EDIT_URL as editUrl,
  USER_CHANGE_PASS as changePassUrl,
  USER_INVITE_URL as userInviteUrl,
  WELCOME_CONFIRM_URL as confirmWelcomeUrl,
} from "../constants";
import { toast } from "react-toastify";

const slice = createSlice({
  name: "user",
  initialState: initialState.user,
  reducers: {
    userRequested: (user) => {
      user.loading = true;
    },
    userRecieved: (user, action) => {
      user.currentUser = action.payload.result;
      user.shouldUpdate = false;
    },
    userFetchError: (user) => {
      user.loading = false;
    },
    userEditRequested: (user) => {
      user.loading = true;
    },
    userEdited: (user, action) => {
      toast.info("User updated successfully.", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
        autoClose: 3000
      });

      user.currentUser = action.payload.result;
      user.shouldUpdate = true;
    },
    userEditError: (user) => {
      user.loading = false;
    }, 
    passwordChangeRequested: (user) => {},
    passwordChanged: (user, action) => {
      toast.info("user password updated successfully", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },
    passwordChangeFailed: (user) => {},

    welcomeConfirmRequested: (user) => {},
    welcomeConfirmed: (user, action) => {
      toast.info("The message will not be shown again", {
        position: toast.POSITION.BOTTOM_RIGHT,
        theme: "colored",
      });
    },
    welcomeConfirmFailed: (user) => {},

    userInviteRequested: (user) => {},
    userInviteCreated: (user) => {},
    userInviteError: (user) => {}, 
  },
});

const {
  userRequested,
  userRecieved,
  userFetchError,
  userEditRequested,
  userEdited,
  userEditError,
  passwordChangeRequested,
  passwordChanged,
  passwordChangeFailed,
  welcomeConfirmRequested,
  welcomeConfirmed,
  welcomeConfirmFailed,
  userInviteRequested,
  userInviteCreated,
  userInviteError,
} = slice.actions;
export default slice.reducer;

export const getCurrentUser = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url,
      onStart: userRequested.type,
      onSuccess: userRecieved.type,
      onError: userFetchError.type,
    })
  );
};

export const editProfile = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: editUrl,
      method: "PUT",
      data,
      onStart: userEditRequested.type,
      onSuccess: userEdited.type,
      onError: userEditError.type,
    })
  );
};

export const changePassword = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: changePassUrl,
      method: "PUT",
      data,
      onStart: passwordChangeRequested.type,
      onSuccess: passwordChanged.type,
      onError: passwordChangeFailed.type,
    })
  );
};

export const confirmWelcome = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: confirmWelcomeUrl,
      method: "PUT",
      data,
      onStart: welcomeConfirmRequested.type,
      onSuccess: welcomeConfirmed.type,
      onError: welcomeConfirmFailed.type,
    })
  );
};


export const inviteUser = (data) => (dispatch) => {
  dispatch(
    apiCallBegan({
      url: userInviteUrl,
      method: "POST",
      data,
      onStart: userInviteRequested.type,
      onSuccess: userInviteCreated.type,
      onError: userInviteError.type,
    })
  );
};
