import { Col } from "react-bootstrap";
import { useSelector } from "react-redux";
import InfoCell from "../infoCell/infoCell";
import { useEffect, useReducer } from "react";
import {TASK_CHART_CHARTS} from '../../store/constants'

const reducer = (state, action) => {
  switch (action.type) {
    case "UPDATE_FILTERS_SET":
      return {
        ...state,
        isProjectSet: action.payload.isProjectSet,
        isSubjectSet: action.payload.isSubjectSet,
      }
    default:
      return state;
  }
}

const DashboardSummary = ({ getDashboardData, taskPage = false }) => {
  const [state, dispatch] = useReducer(reducer, {
    isProjectSet: false,
    isSubjectSet: false,
  });
  const { activeChart } = useSelector((state) => state.entities.tasks);

  const { dashboard } = useSelector((state) => state.entities);
  const {
    projects: { list: projects },
    subjects: { list: subjects },
    tasks: { list: tasks }
  } = useSelector((state) => state.entities);

  useEffect(() => {
    dispatch({
      type: "UPDATE_FILTERS_SET",
      payload: {
        isProjectSet: !!dashboard.selectedProjectId,
        isSubjectSet: !!dashboard.selectedSubjectId,
      }
    });
  }, [dashboard.selectedSubjectId, dashboard.selectedProjectId, dashboard.selectedTaskId])

  useEffect(() => {
    const path = window.location.pathname;
    const ids = {
      taskId: dashboard.selectedTaskId || (path.match(/\/session\/([^/]+)/) || [])[1] || null,
      subjectId: dashboard.selectedSubjectId || (path.match(/\/subject\/([^/]+)/) || [])[1] || null,
      projectId: dashboard.selectedProjectId || (path.match(/\/project\/([^/]+)/) || [])[1] || null,
    };
  
    getDashboardData(ids);
  }, [
    projects,
    subjects,
    tasks,
    dashboard.shouldUpdate,
    dashboard.selectedProjectId,
    dashboard.selectedSubjectId,
    dashboard.selectedTaskId,
    getDashboardData
  ]);

  if (taskPage && activeChart.id !== TASK_CHART_CHARTS) {
    return null
  }

  return (
    <>
      {!state.isProjectSet && (<Col><InfoCell valueSize={20} titleSize={16} value={dashboard.data.analytics.num_projects || dashboard.data.projectCount} title="Projects" isLoading={dashboard.loadingQueries.length > 0} /></Col>)}
      {!state.isSubjectSet && (<Col><InfoCell valueSize={20} titleSize={16} value={dashboard.data.analytics.num_subjects || dashboard.data.subjectCount} title="Subjects" isLoading={dashboard.loadingQueries.length > 0} /></Col>)}
      {!taskPage && (<Col><InfoCell valueSize={20} titleSize={16} value={dashboard.data.analytics.num_tasks || dashboard.data.taskCount} title="Sessions" isLoading={dashboard.loadingQueries.length > 0} /></Col>)}
      {(<Col className="flex align-items-center justify-content-center"><InfoCell valueSize={20} titleSize={16} value={Math.round(dashboard.data.analytics.duration || dashboard.data.minutesRecorded)} title="Min rec." isLoading={dashboard.loadingQueries.length > 0} /></Col>)}
      {(<Col><InfoCell valueSize={20} titleSize={16} value={dashboard.data.analytics.num_feedbacks || 0} title="Feedbacks" isLoading={dashboard.loadingQueries.length > 0} /></Col>)}
      {(<Col><InfoCell valueSize={20} titleSize={16} value={dashboard.data.analytics.num_alerts || 0} title="Alerts" isLoading={dashboard.loadingQueries.length > 0} /></Col>)}
    </>
  );
}

export default DashboardSummary;
