import React, { useEffect } from "react";
import { withTranslation } from "react-i18next";
import { Container, Row, Col } from "react-bootstrap";
import { Formik, Form } from "formik";
import { connect, useSelector } from "react-redux";
import { register, resetStateUserRegister } from "../store/slices/auth";
import { Link, Redirect } from "react-router-dom";
import { SignUpSchema } from "../schemas/SignUpSchema";
import { FormField } from "../components/form/formField";
import authService from "../services/authService";

const RegisterPage = ({ t, register, currentUser, resetStateUserRegister }) => {
  const { errors: serverErrors, isRegistrationDone } = useSelector(
    (state) => state.entities.auth
  );

  useEffect(() => {
    return () => resetStateUserRegister();
  }, [resetStateUserRegister]);

  if (!!currentUser) {
    authService.logout()
  }

  return (
    <Container fluid className="h-100">
      <Row className="h-100 align-content-center px-5" xs={1} sm={1} md={2} lg={2}>
        <Col className="flex align-items-center justify-content-center">
          <Formik
            enctype="multipart/form-data"
            validationSchema={SignUpSchema}
            initialValues={{
              inviteCode: "",
              email: "",
              password: "",
              passwordConfirmation: "",
              fullName: "",
              company: "",
            }}
            onSubmit={(values) => register(values)}
          >
            {({ errors: formErrors, touched, values, setFieldValue }) => {
              useEffect(() => {
                const formattedInviteCode = values.inviteCode
                  .toUpperCase()
                  .replace(/[^A-Z0-9]/g, "")
                  .slice(0, 12)
                  .match(/.{1,4}/g)
                  ?.join("-") ?? "";

                setFieldValue("inviteCode", formattedInviteCode);
              }, [values.inviteCode, setFieldValue]);

              return (
                <Form className="form compact flex flex-direction-column">
                  {!isRegistrationDone && (
                    <>
                      <div className="invite-section mb-4">
                        <FormField
                          name="inviteCode"
                          type="text"
                          required={true}
                          labelName="Invite code"
                          clientErrors={formErrors}
                          serverErrors={serverErrors}
                          touched={touched}
                        />
                      </div>

                      {[
                        {
                          name: "email",
                          type: "email",
                          required: true,
                          labelName: "Email",
                        },
                        {
                          name: "password",
                          type: "password",
                          required: true,
                          labelName: "Password",
                        },
                        {
                          name: "passwordConfirmation",
                          type: "password",
                          required: true,
                          labelName: "Repeat password",
                        },
                        {
                          name: "fullName",
                          type: "text",
                          required: true,
                          labelName: "Full name",
                        },
                        {
                          name: "company",
                          type: "text",
                          required: false,
                          labelName: "Company",
                        },
                      ].map((formProps, index) => (
                        <FormField
                          {...formProps}
                          key={`${formProps.name}-${index}`}
                          clientErrors={formErrors}
                          serverErrors={serverErrors}
                          touched={touched}
                        />
                      ))}

                      <button className="button wide align-self-end" type="submit">
                        Sign Up
                      </button>

                      <p className="text-white align-self-end">
                        Already have an account?{" "}
                        <Link className="link" to="/account/login">
                          {" "}
                          Sign In{" "}
                        </Link>
                      </p>
                    </>
                  )}

                  {isRegistrationDone && (
                    <>
                      <p className="text-white">
                        Account has been created successfully. An activation link has been sent to{" "}
                        <span className="text-strong">{values.email}</span>.
                      </p>

                      <p className="text-white align-self-end">
                        <Link className="link" to="/account/login">
                          {" "}
                          Sign in{" "}
                        </Link>
                      </p>
                    </>
                  )}
                </Form>
              );
            }}
          </Formik>
        </Col>

        <Col
          className="flex align-items-center justify-content-center"
          xs={{ order: "first" }}
          sm={{ order: "first" }}
          md={{ order: "last" }}
          lg={{ order: "last" }}
        >
          <img
            src="/images/register-illustration.svg"
            alt="login"
            style={{ width: "100%", height: "auto", minWidth: 200 }}
          />
        </Col>
      </Row>
    </Container>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    register: (data) => dispatch(register(data)),
    resetStateUserRegister: () => dispatch(resetStateUserRegister()),
  };
}

export default connect(
  null,
  mapDispatchToProps
)(withTranslation()(RegisterPage));