import { Col, Row } from "react-bootstrap";
import GradeDonutChartAM5 from "../../charts/gradeDonutChartAM5";
import GradeSpectrumChartAM5 from "../../charts/gradeSpectrumChartAM5";
import XYChartAM5 from "../../charts/XYChartAM5";
import { INSIGHTS } from "../../../utils/constants";
import { BASELINE_COLOR, POOR_COLOR, SUFFICIENT_COLOR } from "./colors";


const InsightPanel = ({ className, insightKey, data }) => {
  const insightTitle = INSIGHTS[insightKey];

  return (
    <Row className={`${className} redesign-card`} style={{ margin: "1rem 1rem 0rem", position: "relative", display: "flex" }}>
        <Col style={{ flex: "2", display: "flex", flexDirection: "column" }}>
            <div style={{ flex: 1 }}></div>
                <XYChartAM5
                    title={`${insightTitle} Details`}
                    data={data.analytics[`${insightKey.toLowerCase()}_list`]}
                    timeData={data.analytics.times}
                />
            <div style={{ flex: "0 1 0%" }}>
                <GradeSpectrumChartAM5
                    title={``}
                    categoryField="category"
                    data={data.analytics[`${insightKey.toLowerCase()}_grade_tuples`]}
                    legends={[
                        { name: "Poor", color: POOR_COLOR },
                        { name: "Baseline", color: BASELINE_COLOR },
                        { name: "Excellent", color: SUFFICIENT_COLOR },
                    ]}
                />
            </div>
        </Col>
        <Col style={{ flex: 1 }}>
            <GradeDonutChartAM5
                title={``}
                data={data.analytics[`${insightKey.toLowerCase()}_grade_summary`]}
                categoryField="name"
                valueField="value"
                colors={[POOR_COLOR, BASELINE_COLOR, SUFFICIENT_COLOR]}
                showLegend={true}
                score={data.analytics[`${insightKey.toLowerCase()}_grade_summary`]?.score}
            />
        </Col>
    </Row>

  );
};

export default InsightPanel;
