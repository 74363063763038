import { Link } from "react-router-dom";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { getDeviceStatus } from "../../store/slices/devices";
import { capitalize } from "../../utils/stringUtils";
import IconButton from "../common/iconButton";
import AddNewItem from "../listItem/addNewItem";
import DeviceSelectModal from "../deviceSelectModal";
import { createTaskWidget } from "../../store/slices/tasks";
import { TASK } from "../../utils/constants";

const SubjectDetailsHeader = ({ subject, project, subjectsHref, tasksHref, createTask, getDeviceStatus, handleOptionsToggle, connectedDevice, error, className="" }) => {
  const [showDeviceModal, setShowDeviceModal] = useState(false);

  useEffect(() => {
    let interval;
  
    const fetchStatus = () => getDeviceStatus();
  
    if (!error || !error?.includes("Network Error")) {
      fetchStatus();
      interval = setInterval(fetchStatus, 15000);
    } else {
      console.warn("Stopping widget status checks due to network issues.");
    }
  
    return () => clearInterval(interval);
  }, [error, getDeviceStatus]);
  

  return (
    <>
      {subject && (
        <div className={`items-container-heading ${className}`} key={subject.objectId}>
          <div className="d-flex justify-content-space-between">
            <div className="w-100">
              <div className="d-flex align-items-center justify-content-space-between">
                <div className="d-flex align-items-baseline text-white text-medium gap-2">
                  {subjectsHref && <Link to={subjectsHref} className="text-link text-heading"> Subjects </Link>}
                  <span> / </span>
                  <span className="text-link"> {subject.name} </span>
                  {tasksHref && (
                    <>
                     <span> / </span>
                     <Link to={tasksHref} className="text-link text-heading"> Sessions </Link>
                    </>
                  )}
                </div>

                <div className="d-flex gap-3 align-items-center position-relative">
                  {handleOptionsToggle && (
                    <IconButton
                      icon="sliders"
                      onClick={() => handleOptionsToggle()}
                    />
                  )}

                  <AddNewItem
                    source={TASK}
                    onSubmit={createTask}
                    extraInfo={{ projectId: project?.objectId, subjectId: subject.objectId, subjectName: subject.username }}
                    className="show-on-desktop"
                  />

                  {connectedDevice && (
                    <span className="connectivity-indicator"></span>
                  )}

                  <IconButton
                    icon="devices"
                    onClick={() => setShowDeviceModal(true)}
                    title="Select and Connect a Device"
                  />
                </div>
              </div>
              <p className="text-paragraph text-initial">
                {capitalize(subject.gender)}, {subject.age} y.o., {capitalize(subject.handedness)} handed
              </p>
              {!tasksHref && (
                <p className="text-paragraph text-initial">
                  {subject.description}
                </p>
              )}
            </div>
          </div>

          <DeviceSelectModal
            show={showDeviceModal}
            handleClose={() => setShowDeviceModal(false)}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = (state) => ({
  connectedDevice: state.entities.devices.connectedDevice,
  error: state.entities.devices.error,
});

function mapDispatchToProps(dispatch) {
  return {
    createTask: (data) => dispatch(createTaskWidget(data)),
    getDeviceStatus: () => dispatch(getDeviceStatus()),
  };
}

export { SubjectDetailsHeader };
export default connect(mapStateToProps, mapDispatchToProps)(SubjectDetailsHeader);