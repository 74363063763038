import React from "react";
import { useSelector, connect } from "react-redux";
import { TASK_CHARTS } from "../../store/constants";
import { selectChart } from "../../store/slices/tasks";
import { Navbar, Nav, Container, OverlayTrigger, Tooltip } from "react-bootstrap";

const TaskChartNavigator = ({ setActiveChart }) => {
  const { data } = useSelector((state) => state.entities.dashboard);
  const { activeChart } = useSelector(state => state.entities.tasks);

  return (
    <Navbar variant="dark" className="mt-4">
      <Container fluid className="p-0">
        <Nav className="gap-4">
          {Object.values(TASK_CHARTS).map((chart) => {
            const isReportHub = chart.title === "Report Hub";
            const isDisabled = isReportHub && (data.analytics.times === undefined || data.analytics.duration === undefined || data.analytics.duration === 0);

            const linkContent = (
              <Nav.Link
                className={`text-medium text-white ${activeChart.id === chart.id ? "text-strong" : ""} ${isDisabled ? "disabled text-muted" : ""}`}
                href={`#${chart.id}`}
                active={activeChart.id === chart.id}
                key={chart.id}
                onClick={(e) => {
                  if (isDisabled || activeChart.id === chart.id) {
                    e.preventDefault();
                    return;
                  }
                  setActiveChart(chart);
                }}>
                {chart.title}
              </Nav.Link>
            );

            return isDisabled ? (
              <OverlayTrigger
                key={chart.id}
                placement="top"
                overlay={<Tooltip>No analytics reports available for selected session</Tooltip>}
              >
                <span>{linkContent}</span>
              </OverlayTrigger>
            ) : (
              linkContent
            );
          })}
        </Nav>
      </Container>
    </Navbar>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setActiveChart: (chartType) => dispatch(selectChart(chartType)),
});

export default connect(null, mapDispatchToProps)(TaskChartNavigator);
