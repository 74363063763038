import { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { connect, useSelector } from "react-redux";
import { confirmWelcome, getCurrentUser } from "../../store/slices/user";

const WelcomeGuide = ({ currentUser, getCurrentUser, confirmWelcome }) => {
  const [visible, setVisible] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const { isLoggedIn } = useSelector((state) => state.entities.auth);

  useEffect(() => {
    if (isLoggedIn && !currentUser) {
      getCurrentUser();
    }
  }, [isLoggedIn, currentUser, getCurrentUser]);

  useEffect(() => {
    if (currentUser?.showWelcome) {
      setVisible(true);
    }
  }, [currentUser]);

  const handleClose = () => {
    if (dontShowAgain) {
      confirmWelcome({ showWelcome: false });
    }
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <Card className="welcome-guide">
      <Card.Body>
        <h5 className="fw-bold">Welcome to Your InnoBrain Workspace! 🎉</h5>
        <p>
          Let’s get you set up for a seamless experience. <br />
          To start analyzing brain signals, you’ll need to install and run a small setup file to connect your hardware. 
          Once that’s done, you’re all set to explore! <br />
          👈 <strong>Download the setup file from the left sidebar to begin.</strong>
        </p>

        {/* Row for Checkbox + Button */}
        <div className="d-flex align-items-center justify-content-between">
          <Form.Check 
            type="checkbox" 
            label="Don't show again" 
            checked={dontShowAgain} 
            onChange={(e) => setDontShowAgain(e.target.checked)} 
          />
          <Button variant="secondary" onClick={handleClose}>Close</Button>
        </div>
      </Card.Body>
    </Card>
  );
};

const mapStateToProps = (state) => ({
  currentUser: state.entities.user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => dispatch(getCurrentUser()),
  confirmWelcome: (data) => dispatch(confirmWelcome(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(WelcomeGuide);
