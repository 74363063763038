import React, { useEffect } from "react";
import { Button } from "react-bootstrap";
import { connect } from "react-redux";
import { getDownloadLink } from "../../utils/taskUtils";
import { downloadData, clearFileData } from "../../store/slices/tasks";
import LoadingComponent from "../loadingComponent/loadingComponent";

const ExportDataMenu = ({ task, loading, downloadData, fileData, clearFileData }) => {

  const handleDownload = async () => {
    if (!task) {
      const path = window.location.pathname;
      const match = path.match(/\/session\/([^/]+)/);
      task = match ? match[1] : null;
    }
    
    if (!task) {
      console.error("Session ID could not be determined.");
      return;
    }

    const downloadLink = getDownloadLink(task);

    downloadData(downloadLink);
  };

  const triggerFileDownload = (fileData) => {
    const link = document.createElement("a");
    const url = window.URL.createObjectURL(new Blob([fileData]));
    link.href = url;
    link.setAttribute("download", "session-data.edf");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);

  };
  
  useEffect(() => {
    if (fileData) {
      triggerFileDownload(fileData);
      clearFileData();
    }
  }, [fileData]);

  return (
    <div>
      <h4>
        <Button
          variant="outline-light ms-1"
          size="sm"
          onClick={handleDownload}
          disabled={loading}
        >
          Download .edf Data
        </Button>
      </h4>
      {loading && (
        <div style={{ marginTop: "10px", color: "white" }}>
          <LoadingComponent />
          <p>Processing session data. Your download should begin shortly...</p>
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => ({
  loading: state.entities.tasks.loading,
  fileData: state.entities.tasks.fileData,
});

const mapDispatchToProps = (dispatch) => ({
  downloadData: (url) => dispatch(downloadData(url)),
  clearFileData: () => dispatch(clearFileData()),
});

export default connect(mapStateToProps, mapDispatchToProps)(ExportDataMenu);
