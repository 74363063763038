import React, { useEffect } from "react";
import { connect, useSelector } from "react-redux";
import TasksListPage from "./tasksListPage";
import SubjectsListPage from "./subjectsListPage";
import TaskCharts from "../components/taskCharts";
import ProjectsListPage from "./projectsListPage";
import TasksDetailsPage from "./taskDetailsPage";
import { Container, Row, Col } from "react-bootstrap";
import { emptyFill, emptyLine, Themes } from "@arction/lcjs";
import { Switch, Route, Redirect } from "react-router-dom";
import { DashboardSummary, DashboardCharts } from "../components/dashboard";
import { introduceSubject } from "../store/slices/socket";
import { getUserId } from "../services/authService";

const chartsTheme = {
  ...Themes.turquoiseHexagon,
  lcjsBackgroundFillStyle: emptyFill,
  lcjsBackgroundStrokeStyle: emptyLine,
  boxFigureLineStyle: emptyLine
}

const DashboardPage = ({ introduceSubject }) => {
  const { isConnected } = useSelector((state) => state.entities.socket);

  useEffect(() => {
    if (isConnected) {
      introduceSubject(getUserId());
    }
  }, [isConnected, introduceSubject]);

  return (
    <Container fluid style={{ padding: 26, paddingLeft: 16, paddingRight: 16 }}>
      <Row className="h-100" xs={1} xxl={2}>
        <Col xs={{ order: "last" }} xxl={{ span: 8, order: "first" }}>
          <div className="text-white dashboard">
            <Switch>
              <Route exact path={`/dashboard/projects/project/:projectId/subject/:subjectId/session/:taskId/`}>
                <TaskCharts />
                <Container fluid>
                  <Row xs={2} xxl={6}>
                    <DashboardSummary taskPage={true} />
                  </Row>
                </Container>
                <DashboardCharts globalTheme={chartsTheme} taskPage={true} />
              </Route>

              <Route path={`/dashboard/projects/*`}>
                <h2 className="title"> Dashboard </h2>

                <Container fluid>
                  <Row xs={2} xxl={6}>
                    <DashboardSummary />
                  </Row>
                </Container>

                <DashboardCharts globalTheme={chartsTheme} />
              </Route>
            </Switch>
          </div>
        </Col>

        <Col xxl={4} className="dashboard-sidebar">
          <Switch>
            <Redirect exact from={"/dashboard"} to={`dashboard/projects/`} />

            <Route exact path={`/dashboard/projects/project/:projectId/subject/:subjectId/session/:taskId/`}>
              <TasksDetailsPage />
            </Route>

            <Route exact path={`/dashboard/projects/project/:projectId/subject/:subjectId/`}>
              <TasksListPage />
            </Route>

            <Route exact path={`/dashboard/projects/project/:projectId/`}>
              <SubjectsListPage />
            </Route>

            <Route exact path={`/dashboard/projects/`}>
              <ProjectsListPage />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  );
};

function mapStateToProps(state) {
  const { additionalData } = state.entities.projects;
  return { additionalData };
}

function mapDispatchToProps(dispatch) {
  return {
    introduceSubject: (id) => dispatch(introduceSubject(id)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPage);
