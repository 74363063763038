import * as am5 from "@amcharts/amcharts5";
import { useRef, useLayoutEffect } from 'react';
import * as am5XY from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import { BASELINE_COLOR, POOR_COLOR, SUFFICIENT_COLOR } from "../dashboard/utils/colors";

const GradeSpectrumChartAM5 = ({ title, data, categoryField, legends }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);

    const chartData = []

    const getFill = (value) => {
        if (value === 75) {
        return POOR_COLOR
        } else if (value === 100) {
        return BASELINE_COLOR
        } else {
        return SUFFICIENT_COLOR
        }
    }

    for (let i = 0; i < data.length; i++) {
        const metric = data[i]
        const fromDate = new Date();
        const toDate = new Date();
        const splittedFromDate = metric[0].split(":")
        const splittedToDate = metric[1].split(":")

        fromDate.setHours(parseInt(splittedFromDate[0]))
        fromDate.setMinutes(parseInt(splittedFromDate[1]))
        fromDate.setSeconds(Math.round(parseInt(splittedFromDate[2])))

        toDate.setHours(parseInt(splittedToDate[0]))
        toDate.setMinutes(parseInt(splittedToDate[1]))
        toDate.setSeconds(Math.round(parseInt(splittedToDate[2])))

        chartData.push({
            category: "",
            from: fromDate.getTime(),
            to: toDate.getTime(),
            columnSettings: {
                fill: getFill(metric[2])
            }
        })
    }

    useLayoutEffect(() => {
        if (!chartData || chartData.length === 0) return;
    
        const root = am5.Root.new(containerRef.current);
        root.setThemes([
            am5themes_Animated.new(root),
            am5themes_Dark.new(root),
            am5themes_Responsive.new(root)
        ]);
        root._logo.dispose();
        root.durationFormatter.set("durationFormat", "hh:mm:ss");
    
        const chart = root.container.children.push(am5XY.XYChart.new(root, {
            panX: false,
            panY: false,
            paddingRight: 25,
            paddingLeft: 25,
            layout: root.verticalLayout
        }));
    
        const minTimestamp = Math.min(...chartData.map(d => d.from));
        const maxTimestamp = Math.max(...chartData.map(d => d.to));
        const durationMilliseconds = maxTimestamp - minTimestamp;
    
        const normalizedData = chartData.map(d => ({
            ...d,
            from: d.from - minTimestamp,
            to: d.to - minTimestamp
        }));
    
        const xRenderer = am5XY.AxisRendererX.new(root, {});
    
        const xAxis = chart.xAxes.push(am5XY.DurationAxis.new(root, {
            baseUnit: "millisecond",
            renderer: xRenderer
        }));
    
        xAxis.set("min", 0);
        xAxis.set("max", durationMilliseconds);
    
        xAxis.get("renderer").labels.template.setAll({
            fontSize: 12,
            fill: am5.color("#BDBDBD"),
            text: "{value.formatDuration('hh:mm:ss', '00:00:00')}"
        });
        xAxis.get("renderer").grid.template.set("forceHidden", true);

        const yAxis = chart.yAxes.push(am5XY.CategoryAxis.new(root, {
            categoryField: categoryField,
            renderer: am5XY.AxisRendererY.new(root, {})
        }));
        yAxis.data.setAll([{ category: "" }]);
        yAxis.get("renderer").labels.template.setAll({
            fill: am5.color("#BDBDBD"),
            fontSize: 12
        });
        yAxis.get("renderer").labels.template.set("forceHidden", true);
        yAxis.get("renderer").grid.template.set("forceHidden", true);

        const series = chart.series.push(am5XY.ColumnSeries.new(root, {
            xAxis: xAxis,
            yAxis: yAxis,
            valueXField: "to",
            openValueXField: "from",
            categoryYField: categoryField
        }));

        series.columns.template.setAll({
            strokeWidth: 0,
            strokeOpacity: 0,
            height: am5.percent(50),
            templateField: "columnSettings"
        });

        series.data.setAll(normalizedData);

        chartRef.current = chart;
        chart.appear(1000, 100);
    
        return () => {
            root.dispose();
        };
    
    }, [data]);

    return (
        <div>
            {title && <p className="text-larger font-weight-bold text-center">{title}</p>}
            <div ref={containerRef} style={{ minHeight: 150 }}></div>
        </div>
    );
};

export default GradeSpectrumChartAM5;
