import * as am5 from "@amcharts/amcharts5";
import { useRef, useLayoutEffect } from "react";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Responsive from "@amcharts/amcharts5/themes/Responsive";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const XYChartAM5 = ({ title, data, timeData }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);

    useLayoutEffect(() => {
        if (!data || !timeData || data.length === 0 || timeData.length === 0) return;

        const parsedTimeData = timeData.map((time) => {
            const [hh, mm, ss] = time.split(":");
            const [s, ms] = ss.split(".");
        
            const date = new Date();
            date.setHours(parseInt(hh, 10), parseInt(mm, 10), parseInt(s, 10), parseInt(ms.substring(0, 3), 10));
        
            return date.getTime();
        });
        
        const minTimestamp = Math.min(...parsedTimeData);
        const maxTimestamp = Math.max(...parsedTimeData);
        const durationMilliseconds = maxTimestamp - minTimestamp;
        
        const normalizedData = parsedTimeData.map((time, index) => ({
            time: time - minTimestamp,
            value: data[index],
        }));

        const root = am5.Root.new(containerRef.current);
        root.setThemes([
            am5themes_Animated.new(root),
            am5themes_Dark.new(root),
            am5themes_Responsive.new(root)
        ]);
        root._logo.dispose();

        const chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            paddingRight: 25,
            paddingLeft: 25,
            layout: root.verticalLayout
        }));

        const xAxis = chart.xAxes.push(am5xy.DurationAxis.new(root, {
            baseUnit: "millisecond",
            renderer: am5xy.AxisRendererX.new(root, {})
        }));
    
        xAxis.set("min", 0);
        xAxis.set("max", durationMilliseconds);
    
        xAxis.get("renderer").labels.template.setAll({
            fontSize: 12,
            fill: am5.color("#BDBDBD"),
            text: "{value.formatDuration('hh:mm:ss')}"
        });
        xAxis.get("renderer").grid.template.set("forceHidden", true);
        xAxis.get("renderer").labels.template.set("forceHidden", true);

        const yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererY.new(root, {}),
        }));
        yAxis.get("renderer").labels.template.set("forceHidden", true);

        const series = chart.series.push(am5xy.LineSeries.new(root, {
            name: title,
            xAxis: xAxis,
            yAxis: yAxis,
            valueYField: "value",
            valueXField: "time",
        }));

        series.data.setAll(normalizedData);
        chartRef.current = chart;
        chart.appear(1000, 100);

        return () => {
            root.dispose();
        };
    }, [data, timeData, title]);

    return (
        <div>
            {title && <p className="text-larger font-weight-bold text-center">{title}</p>}
            <div ref={containerRef} style={{ width: "100%", minHeight: "200px" }} />
        </div>
    );
};

export default XYChartAM5;