import { TASK_CHARTS, TASK_CHART_EEG } from "./constants";

const initialState = {
  auth: {
    user: null,
    token: null,
    error: null,
    errors: {
      email: []
    },
    isLoggedIn: false,
    isLoading: false,
    isPasswordResetDone: false,
    isForgotPasswordDone: false,
    isRegistrationDone: false,
  },
  projects: {
    list: [],
    shouldUpdate: false,
  },
  subjects: {
    list: [],
    shouldUpdate: false,
  },
  tasks: {
    list: [],
    loading: false,
    fileData: null,
    shouldUpdate: false,
    activeChart: TASK_CHARTS[TASK_CHART_EEG],
    activeChartPreset: null,
    visibleInsights: null,
    visibleChannels: null,
  },
  devices: {
    lslDevices: [],
    idunDevices: [],
    bluetoothDevices: [],
    virtualDevices: [],
    loading: false,
    error: null,
    connectedDevice: null,
    isStreaming: null,
  },
  user: {
    currentUser: null,
  },
  alerts: {
    alertsList: {},
    feedbacksList: {},
    triggersList: {},
  },
  dashboard: {
    data: {
      taskCount: 0,
      projectCount: 0,
      subjectCount: 0,
      minutesRecorded: 0,
      charts: {
        minutesRecorded: [],
        subjectsPerGender: [],
        subjectsPerAgeGroup: [],
        tasksFrequencyPer90Days: [],
        tasksFrequencyPerDay: [],
      },
      analytics: {
        num_projects: 0,
        num_subjects: 0,
        num_tasks: 0,
        duration: 0,
        num_feedbacks: 0,
        num_alerts: 0,
        num_triggers: 0,
        age_counts: [],
        age_names: [],
        day_hour: [],
        gender_counts: {
          female: 0,
          male: 0,
        },
        month_counts: [],
        month_names: [],
        subjects: [],
        tasks: [],
        taskId: '',
        projects: [],
        attention_grade_tuples: [],
        mental_workload_grade_tuples: [],
        vigilance_grade_tuples: [],
        motion_sickness_grade_tuples: [],
        relaxation_grade_tuples: [],
        stress_grade_tuples: [],
        mental_workload_grade_summary: [],
        motion_sickness_grade_summary: [],
        attention_grade_summary: [],
        stress_grade_summary: [],
        vigilance_grade_summary: [],
        relaxation_grade_summary: [],
        insight_grade_summary: { baseline: 0, score: 0, poor: 0, sufficient: 0 },
      }
    },
    loading: false,
    loadingQueries: [],
    selectedProjectId: '',
    selectedSubjectId: '',
    selectedTaskId: '',
  },
  socket: {
    connection: null,
    isConnected: false,
    isIntroduced: false,
    isSpeedTestDone: false,
    isSpeedTestRunning: false,
    speedTestLatencies: [],
    speedTestRuns: 0,
    connectionLatency: -1,
  }
};

export default initialState;
