import { connect, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col, Spinner, Form } from "react-bootstrap";
import IconButton from "../common/iconButton";
import { getCurrentUser, inviteUser } from "../../store/slices/user";

const InviteUserModal = ({ currentUser, getCurrentUser, inviteUser }) => {
    const [show, setShow] = useState(false);
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    const [isVisible, setIsVisible] = useState(false);
    const { isLoggedIn } = useSelector((state) => state.entities.auth);

    useEffect(() => {
        if (isLoggedIn && !currentUser) {
            getCurrentUser();
        }
    }, [isLoggedIn, currentUser, getCurrentUser]);

    useEffect(() => {
        if (currentUser?.isAdmin) {
            setIsVisible(true);
        } else {
            setIsVisible(false);
        }
    }, [currentUser]);

    const handleSendInvite = () => {
        if (!email.trim()) {
            setError("Email is required.");
            return;
        }
        setError("");
        inviteUser({ email: email, invitedBy: currentUser.objectId });
        setShow(false);
    };

    if (!isVisible) return null;
  
    return (
      <>
        <IconButton
          title="Invite User"
          icon="invite"
          size="large"
          onClick={() => setShow(true)}
        />
        <Modal
          centered
          show={show}
          onHide={() => setShow(false)}
          className="text-center modal"
        >
          <Modal.Header className="p-0" closeButton closeVariant="white">
            <Modal.Title className="text-white text-medium">Invite User</Modal.Title>
          </Modal.Header>
          <Modal.Body className="p-0 pt-3">
            <p className="text-white opacity-75 text-start">
              Send an invitation email to a user's email address
              <br />
            </p>
            <Form.Group className="mb-3">
              <Form.Control
                type="email"
                placeholder="Enter email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                isInvalid={!!error}
              />
              <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
            </Form.Group>
            <Container fluid className="p-0 mt-3">
              <Row>
                <Col xs={6}>
                  <Button className="w-100" variant="secondary" onClick={() => setShow(false)}>
                    Close
                  </Button>
                </Col>
                <Col xs={6}>
                  <Button className="w-100" onClick={handleSendInvite}>
                    Send Invite
                  </Button>
                </Col>
              </Row>
            </Container>
          </Modal.Body>
        </Modal>
      </>
    );
};


const mapStateToProps = (state) => ({
    currentUser: state.entities.user.currentUser,
});

const mapDispatchToProps = (dispatch) => ({
    getCurrentUser: () => dispatch(getCurrentUser()),
    inviteUser: (data) => dispatch(inviteUser(data)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InviteUserModal);
  
  