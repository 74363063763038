import { TaskState } from "./constants";
import { TOKEN_KEY } from "../store/constants";

export const getDownloadLink = (taskId) =>
  `${process.env.REACT_APP_SERVER_URL}/task/data/download/${taskId}/${localStorage.getItem(TOKEN_KEY)}`;

export const cleanChartData = (chartData) =>
  chartData
    .filter((item) => item.time !== undefined)
    .sort((itemLeft, itemRight) => itemLeft.time > itemRight.time ? 1 : -1);

export const filterUniquePoints = (chartData, key) => {
  const seenKeyValues = new Set();

  const filteredData = chartData.filter((point) => {
    if (!seenKeyValues.has(point[key])) {
      seenKeyValues.add(point[key]);
      return true;
    }

    return false;
  });

  console.info(`Filtered joint offline + online data and dropped ${chartData.length - filteredData.length} points.`)
  return filteredData;
};

export const isTaskStreaming = (taskState) =>
  [TaskState.RUNNING, TaskState.PAUSED].includes(taskState);

export const getRangeIconName = (alertType) => {
  alertType = alertType.toLowerCase();

  switch (alertType) {
    case "tired":
      return "tired";
    case "shaking":
      return "shaking";
    case "sleepy":
      return "sleep";
    case "close eye":
      return "eyeclose";
    case "open eye":
      return "eyeopen";
    case "task":
      return "task";
    case "error":
      return "error";
    default:
      return "trigger";
  }
};

export const getRangeIconColor = (alertType) => {
  alertType = alertType.toLowerCase();

  switch (alertType) {
    case "yes":
      return "green";
    case "no":
      return "red";
    default:
      return "default"
  }
};