import { connect, useSelector } from "react-redux";
import { useEffect, useState, useCallback } from "react";
import { startStream, stopStream, pauseStream, resumeStream } from "../../store/slices/devices"
import { patchTask, getTaskDetails } from "../../store/slices/tasks"
import { TaskState } from "../taskChartCommon/taskState";
import IconButton from "../common/iconButton";


const TaskControls = ({ task, startStream, stopStream, pauseStream, resumeStream, patchTask, getTaskDetails, isStreaming }) => {
    const { connection, isConnected } = useSelector((state) => state.entities.socket);
    
    const handleMessage = useCallback(async (data) => {
        const { type } = data;

        switch(type) {
            case "TASK_START":
            case "TASK_RESUME":
                patchTask({ objectId: data.taskId, state: TaskState.RUNNING });
                break;
            case "TASK_PAUSE":
                patchTask({ objectId: data.taskId, state: TaskState.PAUSED });
                break;
            case "TASK_COMPLETE":
                getTaskDetails(data.taskId);
                break;
            default:
                return;
        }
    }, [
        patchTask,
        getTaskDetails
    ]);

    const handleStopStream = (taskId) => {
        if (taskId) {
          stopStream({"taskId": taskId});
        }
    };
    
    useEffect(() => {
    if (isConnected) {
        connection.on("message", handleMessage);
    }

    return () => {
        if (connection) {
        connection.off("message", handleMessage);
        }
    }
    }, [isConnected, connection, handleMessage]);

    return (
        <>
            {task && (
                <div className="task-controls d-flex gap-2">
                    <div className="control-container">
                        <IconButton
                            icon="play"
                            size="medium"
                            onClick={() => {
                                if (task.state === TaskState.CREATED) {
                                    startStream({ task: task.objectId, device: task.device, srate: task.sampleRate, title: task.title });
                                } else {
                                    resumeStream();
                                }
                            }}
                            disabled={!(task.state === TaskState.CREATED || task.state === TaskState.PAUSED)}
                            className={`control-button ${task.state === TaskState.CREATED || task.state === TaskState.PAUSED ? "" : "disabled"}`}
                        />
                        </div>
                        <div className="control-container">
                        <IconButton
                            icon="pause"
                            size="medium"
                            onClick={pauseStream}
                            disabled={task.state !== TaskState.RUNNING}
                            className={`control-button ${task.state === TaskState.RUNNING ? "" : "disabled"}`}
                        />
                        </div>
                        <div className="control-container">
                        <IconButton
                            icon="stop"
                            size="medium"
                            onClick={() => handleStopStream(task.objectId)}
                            disabled={!(task.state === TaskState.RUNNING || task.state === TaskState.PAUSED)}
                            className={`control-button ${task.state === TaskState.RUNNING || task.state === TaskState.PAUSED ? "" : "disabled"}`}
                        />
                    </div>
                </div>
            )}
        </>
    );
};

const mapStateToProps = (state) => ({
    isStreaming: state.entities.devices.isStreaming,
});
  
const mapDispatchToProps = (dispatch) => ({
    startStream: (data) => dispatch(startStream(data)),
    stopStream: (data) => dispatch(stopStream(data)),
    pauseStream: () => dispatch(pauseStream()),
    resumeStream: () => dispatch(resumeStream()),
    patchTask: (data) => dispatch(patchTask(data)),
    getTaskDetails: (taskId) => dispatch(getTaskDetails(taskId)),
});

export { TaskControls };
export default connect(mapStateToProps, mapDispatchToProps)(TaskControls);
