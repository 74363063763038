import moment from "moment";
import { useEffect, useState, useRef } from "react";
import { TaskState } from "../taskChartCommon/taskState";
import { formatTaskDuration } from "../../utils/stringUtils";

const getStreamingStatusClass = (state) => {
  switch (state) {
    case TaskState.PAUSED:
      return "paused";
    case TaskState.RUNNING:
      return "active";
    case TaskState.CREATED:
    case TaskState.COMPLETED:
    default:
      return "inactive"
  }
}

const TaskDetailsHeader = ({ task }) => {
  const intervalId = useRef(null);
  const [duration, setDuration] = useState(0);

  useEffect(() => {
    if (task?.duration !== undefined && !intervalId.current && !(task.duration === 0 && task.state === TaskState.RUNNING)) {
      setDuration(task.duration);
    }
  }, [task, duration]);
  
  useEffect(() => {
    if (!task) return;

    switch(task.state) {
      case TaskState.RUNNING:
        if (intervalId.current) {
          clearInterval(intervalId.current);
        }

        intervalId.current = setInterval(() => setDuration((counter) => counter + 1), 1000);
        break;
      case TaskState.PAUSED:
        break;
      default:
        clearInterval(intervalId);
        intervalId.current = null;
    }

    return () => clearInterval(intervalId.current);
  }, [task?.state, task]);

  return (
    <>
      {task && (
        <div className="item-details text-white">
          <span> {task.title} </span>
          <div className="d-flex gap-3 mb-4 justify-content-space-between align-items-center">
            <div className="d-flex gap-4">
              <span> {moment(task.createdAt).format("YYYY-MM-DD HH:mm:ss")} {task.completedAt ? "~ " + moment(task.completedAt).format("YYYY-MM-DD HH:mm:ss") : "" } </span>
            </div>

            <div className="d-flex gap-3 align-items-center">
              {formatTaskDuration(Math.round((duration || 0)))}
              <span className={`streaming-status ${getStreamingStatusClass(task.state)}`}></span>
              </div>
          </div>

          <p> {task.description ? task.description : ""} </p>
        </div>
      )}
    </>
  );
};

export { TaskDetailsHeader };
export default TaskDetailsHeader;