import * as am5 from "@amcharts/amcharts5";
import { useRef, useLayoutEffect, useState } from "react";
import * as am5Percent from "@amcharts/amcharts5/percent";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

const GradeDonutChartAM5 = ({ title, data, categoryField, valueField, colors, score, showLegend = true }) => {
    const chartRef = useRef(null);
    const containerRef = useRef(null);

    const chartData = [{
        "name": "Poor",
        "value": data.poor || 0,
      }, {
        "name": "Baseline",
        "value": data.baseline || 0,
      }, {
        "name": "Excellent",
        "value": data.sufficient || 0,
    }]

    score = parseFloat(score.toFixed(1))

    useLayoutEffect(() => {
        const root = am5.Root.new(containerRef.current);

        root.setThemes([
            am5themes_Dark.new(root),
            am5themes_Animated.new(root),
        ]);

        root._logo.dispose();

        const chart = root.container.children.push(
            am5Percent.PieChart.new(root, { 
                layout: root.verticalLayout, 
                innerRadius: am5.percent(60)  
            }),
        );

        const series = chart.series.push(am5Percent.PieSeries.new(root, {
            valueField: valueField,
            categoryField: categoryField,
            alignLabels: false,
        }))

        series.labels.template.setAll({
            textType: "circular",
            text: `[fontSize:13px]{valuePercentTotal.formatNumber('0.00')}%`,
            centerX: 0,
            centerY: 0
        });

        const colorSet = am5.ColorSet.new(root, {
            colors: colors,
            passOptions: {
                lightness: -0.05,
                hue: 0
            }
        });
        
        series.set("colors", colorSet);

        const label = chart.seriesContainer.children.push(
            am5.Label.new(root, {
              textAlign: "center",
              centerY: am5.p50,
              centerX: am5.p50,
              text: `[fontSize:20px]Score[/]:\n[bold fontSize:28px]${score}[/]`
            })
        );

        series.data.setAll(chartData);

        const legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.percent(50),
            x: am5.percent(50),
            marginTop: 15,
            marginBottom: 15,
            valueLabels: ''
        }));
        legend.valueLabels.template.set("forceHidden", true);
        legend.data.setAll(series.dataItems);

        chartRef.current = chart;

        series.appear(500);
        chart.appear(500, 100);

        return () => {
            root.dispose();
        }
    }, [data]);

    return (
        <div>
            {title && <p className="text-larger font-weight-bold text-center"> {title} </p>}
            <div ref={containerRef} style={{ minHeight: 350 }}></div>
        </div>
    )
};

export default GradeDonutChartAM5;
