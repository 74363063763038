import Profile from "../profile";
import IconButton from "../common/iconButton";
import { Link, useHistory } from "react-router-dom";
import DownloadWidgetModal from "../downloadWidget";
import InviteUserModal from "../inviteUser";
import WelcomeGuide from "../welcomeGuide";

const Sidebar = ({ className }) => {
  const history = useHistory();

  return (
    <div className={`Sidebar d-flex flex-column gap-4 ${className || ""}`}>
      <Link to="/">
        <img alt="" src="/images/innobrain-logo-multicolor.svg" />
      </Link>
      <Profile />
      <DownloadWidgetModal />
      <WelcomeGuide />
      <InviteUserModal />
      <IconButton
        title="Logout"
        icon="logout"
        size="large"
        onClick={() => history.push("/account/logout")}
      />
    </div>
  );
}

export default Sidebar;