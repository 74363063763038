import axios from "axios";
import moment from "moment";
import "rc-slider/assets/index.css";
import {useSelector, connect} from "react-redux";
import {getUserId} from "../../../services/authService";
import {filterUniquePoints, isTaskStreaming} from "../../../utils/taskUtils";
import {requestSpeedTest} from "../../../store/slices/socket";
import {useEffect, useRef, useReducer, useState, useCallback} from "react";
import {
  TIME_SERIES_PRESETS_ONLINE,
  TIME_SERIES_PRESETS_OFFLINE,
  TimeSeriesScaleByUnit,
  IGNORED_CHANNELS
} from "../../../utils/constants";
import {
  lightningChart,
  SolidFill,
  SolidLine,
  PointShape,
  AxisScrollStrategies,
  AxisTickStrategies,
  ColorRGBA,
  ColorHEX,
  UIElementBuilders,
  LegendBoxBuilders,
  UIOrigins,
  Themes,
  emptyFill,
  emptyLine,
  transparentFill,
  IndividualPointFill,
  UILayoutBuilders,
  UIDraggingModes,
  ImageFill,
  ImageFitMode,
  PointSeries
} from "@arction/lcjs";

// Height of a single channel.
const CHANNEL_HEIGHT_DOM = 100;
const CHANNEL_INTERVAL_Y = 200;

// Delay between page changes.
const PAGE_CHANGE_DELAY = 500;

// Attributes received with points but not rendered as a channel.
const METADATA_KEYS = ["time", "indexTime", "sampleRate"];

// Cached page data.
const DATA_WINDOWS_CACHE = new Map();

// Interval scale constraints.
const INTERVAL_DELTA_MULTIPLIER = 0.8;

// Chart settings.
const ANIMATIONS_ENABLED = {
  xAxis: true,
  yAxis: false,
  chart: false,
}

const reducer = (state, action) => {
  switch (action.type) {
    case "SET_IS_STREAMING":
      return {...state, isStreaming: action.payload.isStreaming}
    case "SET_TASK_RUNNING":
      return {...state, isStreaming: true, isEmpty: false};
    case "SET_IS_EMPTY":
      return {...state, isEmpty: action.payload.isEmpty};
    case "SET_ACTIVE_TAB":
      return {...state, activeTab: action.payload.activeTab, channelsOffset: 0};
    case "SET_ACTIVE_PAGE":
      return {...state, page: {...state.page, active: action.payload.activePage}};
    case "SET_LAST_PAGE":
      return {...state, page: {...state.page, last: action.payload.lastPage}};
    case "SET_IS_REDRAW_PENDING":
      return {...state, isRedrawPending: action.payload.isRedrawPending};
    case "SET_IS_REFETCH_PENDING":
      return {...state, isRefetchPending: action.payload.isRefetchPending};
    case "SET_IS_LOADING":
      return {...state, isLoading: action.payload.isLoading};
    case "SET_PAGE_CHANGE_TIMEOUT_ID":
      return {...state, page: {...state.page, timeoutId: action.payload.pageChangeTimeoutId}};
    case "SET_IS_AXIS_ALIGNED":
      return {...state, isAxisAligned: action.payload.isAxisAligned};
    case "SET_CUSTOM_ZOOM":
      return {...state, customZoom: action.payload.customZoom};
    case "SET_SHOW_QUALITY":
      return {...state, showQuality: action.payload.showQuality};
    case "SET_IS_PREFETCHED":
      return {...state, isPrefetched: action.payload.isPrefetched};
    case "SET_CHART_HEIGHT":
      return {
        ...state,
        chartHeight: {
          ...state.chartHeight,
          ...action.payload.chartHeight
        }
      }
    case "CHANNELS_OFFSET_NEXT":
      if (state.channelsOffset + 1 <= state.activeTab.divider - 1)
        return {
          ...state,
          channelsOffset: state.channelsOffset + 1,
          prevChannelsOffset: state.channelsOffset,
          isRefetchPending: true
        }

      return state;
    case "CHANNELS_OFFSET_PREV":
      if (state.channelsOffset - 1 >= 0)
        return {
          ...state,
          channelsOffset: state.channelsOffset - 1,
          prevChannelsOffset: state.channelsOffset,
          isRefetchPending: true
        }

      return state;
    case "SET_CHART_DATA_UPDATED":
      return {
        ...state,
        isRedrawPending: true,
        isEmpty: state.isStreaming ? !!action.payload.forceEmpty : action.payload.chartPoints?.length === 0,
        chartPoints: action.payload.chartPoints || [],
        windowSize: action.payload.windowSize || state.windowSize,
        isAxisAligned: false,
      };
    case "RESET":
      return {...action.payload.state}
    case "OFFLINE_DATA_SWITCH":
      return {
        ...state,
        isRedrawPending: true,
        isEmpty: state.isStreaming ? !!action.payload.forceEmpty : action.payload.chartPoints?.length === 0,
        chartPoints: action.payload.chartPoints || [],
        chartQuality: action.payload.chartQuality || [],
        windowSize: action.payload.windowSize || state.windowSize,
        isAxisAligned: action.payload.isAxisAligned,
        page: {
          ...state.page,
          last: action.payload.lastPage,
          active: action.payload.activePage,
        }
      }
    case "PAGE_CHANGE_ONLINE":
      return {
        ...state,
        ...action.payload,
        chartHeight: {
          ...state.chartHeight,
          ...action.payload.chartHeight
        }
      }
    case "PRESET_CHANGE":
      return {
        ...state,
        ...action.payload,
        chartHeight: {
          ...state.chartHeight,
          ...action.payload.chartHeight,
        }
      }
    case "CHANNEL_VISIBILITY_CHANGE":
      return { ...state, visibleChannels: action.payload.visibleChannels };
    default: {
      return state;
    }
  }
}

const createChart = (id) => {
  const chart = lightningChart({
    overrideInteractionMouseButtons: {
        chartXYPanMouseButton: 0, // LMB
    }
  }).ChartXY({
    container: id,
    theme: {
      ...Themes.turquoiseHexagon,
      lcjsBackgroundStrokeStyle: emptyLine,
      lcjsBackgroundFillStyle: transparentFill,
      seriesBackgroundFillStyle: transparentFill,
      seriesBackgroundStrokeStyle: emptyLine,
      uiBackgroundFillStyle: new SolidFill({color: ColorRGBA(0, 0, 0, 128)}),
    }
  }).setPadding({top: 0, right: 0, bottom: 40, left: 0});

  chart
    .setTitle("")
    .setMouseInteractionRectangleFit(false)
    .setMouseInteractionRectangleZoom(false)
    .setMouseInteractionWheelZoom(false)
    .setBackgroundStrokeStyle(emptyLine)
    .setBackgroundFillStyle(emptyFill)
    .setAnimationsEnabled(ANIMATIONS_ENABLED.chart)
    .setAutoCursor((autoCursor) => autoCursor
      .setTickMarkerYVisible(false)
      .setTickMarkerXVisible(false)
      .setGridStrokeYStyle(new SolidLine({ 
        thickness: 1, 
        fillStyle: new SolidFill({ color: ColorHEX("#cccccc").setA(125) }) 
      }))
      .setGridStrokeXStyle(new SolidLine({ 
        thickness: 1, 
        fillStyle: new SolidFill({ color: ColorHEX("#cccccc").setA(125) }) 
      }))
    )
  
  const vScrollImg = new Image()
  vScrollImg.src='/images/icons/scroll_vertical.svg'

  chart.getDefaultAxisY()
    .setTickStrategy(AxisTickStrategies.Empty)
    .setScrollStrategy(AxisScrollStrategies.progressive)
    .setChartInteractionFitByDrag(false)
    .setChartInteractionZoomByDrag(false)
    .setChartInteractionPanByDrag(true)
    .setChartInteractionZoomByWheel(false)
    .setNibInteractionScaleByDragging(false)
    .setNibInteractionScaleByWheeling(false)
    .setAnimationsEnabled(ANIMATIONS_ENABLED.yAxis);

  chart
    .getDefaultAxisX()
    .setScrollStrategy(AxisScrollStrategies.progressive)
    .setTickStrategy(AxisTickStrategies.Time)
    .setNibInteractionScaleByDragging(false)
    .setNibInteractionScaleByWheeling(false)
    .setAnimationsEnabled(ANIMATIONS_ENABLED.xAxis);

  return chart;
}

const getTabChannels = (task, tab, offset = 0) => {
  const count = Math.ceil(task.channels.length / tab.divider);
  return task.channels.slice(count * offset, count + count * offset);
};

const getVisibleChannels = (task) => {
  if (!task?.channels || !Array.isArray(task.channels)) {
      return {};
  }

  return task.channels.reduce((acc, value) => {
      acc[value] = true;
      return acc;
  }, {});
};

const ChannelsChart = ({
                         id,
                         task,
                         socket,
                         children,
                         initialData,
                         getTaskDetails,
                         timeSeriesCollection,
                         setActiveChartPreset,
                         setVisibleChannels,
                         timeSeriesSampleRate,
                         ignoreChannelMismatch,
                         requestSpeedTest,
                       }) => {
  const lastDataTime = useRef(0);
  const delayedPointsBuffer = useRef([]);
  const {activeChartPreset, visibleChannels} = useSelector((state) => state.entities.tasks);
  const {connectionLatency, isSpeedTestDone, isSpeedTestRunning} = useSelector((state) => state.entities.socket);
  const speedTestStateRef = useRef({isSpeedTestDone, isSpeedTestRunning});

  const [state, dispatch] = useReducer(reducer, {
    isEmpty: initialData.length === 0,
    isStreaming: isTaskStreaming(task.state),
    isRedrawPending: false,
    isRefetchPending: false,
    isLoading: true,
    activeTab: isTaskStreaming(task.state) ? TIME_SERIES_PRESETS_ONLINE[0] : TIME_SERIES_PRESETS_OFFLINE[0],
    visibleChannels: getVisibleChannels(),
    chartPoints: [],
    chartQuality: [],
    showQuality: false,
    windowSize: 0,
    lastPoint: null,
    isPrefetched: false,
    channelsOffset: 0,
    prevChannelsOffset: 0,
    page: {
      last: 1,
      active: 1,
      timeoutId: null,
    },
    chartHeight: {
      previous: activeChartPreset ? CHANNEL_HEIGHT_DOM * getTabChannels(task, activeChartPreset, 0).length : 600,
      current: activeChartPreset ? CHANNEL_HEIGHT_DOM * getTabChannels(task, activeChartPreset, 0).length : 600
    }
  });

  const [legendSize, setLegendSize] = useState({
    width: 0,
    height: 0
  });

  const chartRef = useRef({
    chart: null,
    series: new Map(),
    yAxisTicks: new Map(),
    legend: null,
  });

  const stateRef = useRef(state);

  const chartSettingsRef = useRef({
    interval: {
      x: {start: null, end: null, deltaMin: null, deltaMax: null},
      y: {start: null, end: null, deltaMin: null, deltaMax: null},
    },
  });

  const addSeries = (key, value) => {
    if (IGNORED_CHANNELS.includes(key)) {
      return
    }

    chartRef.current.series.set(key, value);
  }

  const drawChart = useCallback(() => {
    const chart = chartRef.current.chart;

    const legend =
      chartRef.current.legend ||
      chart.addLegendBox()
        .setTitle("Channels")
        .setOrigin(UIOrigins.RightTop)
        .setPosition({x: 100, y: 100})
        .setPadding({left: stateRef.current.isStreaming ? 20 : 5})

    const qualityLegend =
      chartRef.current.qualityLegend ||
      chart.addLegendBox()
        .setTitle("Channels")
        .setTitleFillStyle(new SolidFill({color: ColorRGBA(0, 0, 0, 0)}))
        .setOrigin(UIOrigins.RightTop)
        .setPadding({left: 0, right: 7, bottom: 22})
        .setPosition({x: 98.5, y: 100})
        .setBackground((background) => background
            .setFillStyle(emptyFill)
            .setStrokeStyle(emptyLine)
        )
    
    const buttonReset = chartRef.current.buttonReset;
    const buttonQuality = chartRef.current.buttonQuality;

    chartRef.current.channelsPageSwitchSlot?.dispose();
    chartRef.current.latencyLabelTextBox?.dispose();
    chartRef.current.latencyLabelSlot?.dispose();

    for (const [key, series] of chartRef.current.series.entries()) {
      series.clear();
      series.dispose();
      chartRef.current.series.delete(key);
    }

    for (const [key, yAxisTick] of chartRef.current.yAxisTicks.entries()) {
      yAxisTick.dispose();
      chartRef.current.yAxisTicks.delete(key);
    }

    legend.entries.forEach(({entry}) => entry.dispose());
    qualityLegend.entries.forEach(({entry}) => entry.dispose());

    chartRef.current = {
      chart,
      legend,
      qualityLegend,
      buttonReset,
      buttonQuality,
      series: new Map(),
      yAxisTicks: new Map(),
    };

    const channels = getTabChannels(task, state.activeTab, state.channelsOffset)
    const channelsCount = channels.length;
    const startIntervalY = -CHANNEL_INTERVAL_Y / 2 - 100;
    const endIntervalY = channelsCount * CHANNEL_INTERVAL_Y;
    const intervalDeltaY = endIntervalY - startIntervalY;

    chartSettingsRef.current.interval.y = {
      start: startIntervalY,
      end: endIntervalY,
      deltaMin: intervalDeltaY - intervalDeltaY * INTERVAL_DELTA_MULTIPLIER,
      deltaMax: intervalDeltaY + intervalDeltaY * INTERVAL_DELTA_MULTIPLIER
    };

    if (state.chartPoints.length === 0) {
      channels.forEach((channelKey, index) => {
        const series = maybeAddSeries(channelKey, index, channelsCount);
        addSeries(channelKey, series.channelSeries)
      });

      chartRef.current.chart
        .getDefaultAxisY()
        .setInterval({
          start: startIntervalY,
          end: endIntervalY,
          stopAxisAfter: true,
        });
    } else {
      state.chartPoints.forEach((points) => {
        channels.forEach((channelKey, index) => {
          const series = maybeAddSeries(channelKey, index, channelsCount);

          series.channelSeries.add({
            x: points["time"],
            y: (points[channelKey] * TimeSeriesScaleByUnit[task.units]) + ((channelsCount - 1) - index) * CHANNEL_INTERVAL_Y,
          });

          addSeries(channelKey, series.channelSeries)

          if (!state.isStreaming) {
            const point = state.chartQuality.find(point => point.time === points["time"]);

            if (point) {
              series.pointSeries.add({
                x: point["time"],
                y: (points[channelKey] * TimeSeriesScaleByUnit[task.units]) + ((channelsCount - 1) - index) * CHANNEL_INTERVAL_Y,
                size: 5,
                color: getSignalColor(point["quality_" + channelKey])
                
              })
            }

            addSeries((channelKey + "_quality"), series.pointSeries);
          }
        });
      });

      chartRef.current.chart
        .getDefaultAxisY()
        .setInterval({
          start: startIntervalY,
          end: endIntervalY,
          stopAxisAfter: true,
        });

      if (state.isStreaming && state.chartPoints.length > 0) {
        const lastPoint = state.chartPoints[state.chartPoints.length - 1];

        chartRef.current.chart.getDefaultAxisX().setInterval({
          start: lastPoint["time"] - stateRef.current.activeTab.durationInSeconds * 1000,
          end: lastPoint["time"],
          animate: false,
          stopAxisAfter: false
        });
      } else {
        let startIntervalX = state.chartPoints[0]["time"];
        let endIntervalX = startIntervalX + state.activeTab.durationInSeconds * 1000;

        chartRef.current.chart
          .getDefaultAxisX()
          .setInterval({
            start: startIntervalX,
            end: endIntervalX,
            animate: false,
            stopAxisAfter: false
          });
      }
    }

    handleChannelVisibilityChange();

    const entries = chartRef.current.qualityLegend.entries;
    entries.forEach((entry) => {
      entry.entry.setTextFillStyle(new SolidFill({color: ColorRGBA(0, 0, 0, 0)}))
    });

    const row = legend.addElement(UILayoutBuilders.Column);
    const latencyLabelTextBox = row.addElement(UIElementBuilders.TextBox).setText(isSpeedTestDone ? `${connectionLatency} ms` : '- ms');
    chartRef.current.latencyLabelSlot = row;
    chartRef.current.latencyLabelTextBox = latencyLabelTextBox

    row.onMouseClick(() => {
      if (speedTestStateRef.current.isSpeedTestDone && !speedTestStateRef.current.isSpeedTestRunning) {
        latencyLabelTextBox.setText('... ms');
        requestSpeedTest(getUserId());
      }
    });

    if (getTabChannels(task, {divider: 1}).length > 1 && state.activeTab.divider > 1) {
      const row = legend.addElement(UILayoutBuilders.Column);
      row.addElement(UIElementBuilders.TextBox).setText(" ");
      row.addElement(UIElementBuilders.TextBox).setText(" ");
      chartRef.current.channelsPageSwitchSlot = row;
    }

    requestAnimationFrame(() => {
      setLegendSize({
        width: legend.size.x,
        height: legend.size.y
      });
    });
  }, [
    connectionLatency,
    isSpeedTestDone,
    requestSpeedTest,
    state.activeTab,
    state.channelsOffset,
    state.chartPoints,
    state.isStreaming,
    task,
  ]);

  const switchDataOffline = useCallback((sampleRate, windowSize, channels) => {
    const queryParams = new URLSearchParams({
      page: 1,
      sampleRate,
      windowSize,
      seriesCollection: timeSeriesCollection,
      seriesSampleRate: timeSeriesSampleRate,
    });

    const channelsParams = channels.map((channel) => (`channels[]=${channel}`)).flat().join("&")
    const urlPoints = `${process.env.REACT_APP_SERVER_URL}/task/${task.objectId}/data?${queryParams.toString()}&${channelsParams}`;
    const urlInsights = `${process.env.REACT_APP_SERVER_URL}/task/${task.objectId}/insights`;

    axios.get(urlPoints).then(({data: {result: {points, pages}}}) => {
      axios.get(urlInsights).then(({data: {result: {insightData}}}) => {
        dispatch({
          type: "OFFLINE_DATA_SWITCH",
          payload: {
            chartPoints: points,
            chartQuality: insightData,
            windowSize: windowSize,
            activePage: 1,
            lastPage: pages,
            isAxisAligned: false
          }
        });

        chartRef.current.buttonQuality?.setVisible(true);
        chartRef.current.buttonReset?.setPosition({x: 90.5, y: 90})
        chartRef.current.qualityLegend?.setVisible(false);
        chartRef.current.legend?.setPadding({left: 5})
      });
    }).catch((error) => {
      console.log(error);
    });
  }, [
    task.objectId,
    timeSeriesCollection,
    timeSeriesSampleRate
  ]);

  const switchDataOnline = useCallback((sampleRate, windowSize, channels) => {
    axios.put(`${process.env.REACT_APP_SERVER_URL}/task/${task.objectId}/filter`, {
      channels,
      sampleRate,
      windowSize,
      collection: timeSeriesCollection,
    }).then((response) => {
      const lastPrefetchPoint = response.data.result.points[response.data.result.points.length - 1];
      console.info("Last pre-fetched data point 'time':", lastPrefetchPoint ? lastPrefetchPoint['time'] : 'None');

      chartRef.current.buttonQuality?.setVisible(false);
      chartRef.current.buttonReset?.setPosition({x: 88, y: 99})
      chartRef.current.qualityLegend?.setVisible(true);
      chartRef.current.legend?.setPadding({left: 20})

      if (!lastPrefetchPoint) {
        dispatch({
          type: "SET_CHART_DATA_UPDATED",
          payload: {
            windowSize,
            isLoading: true,
            isPrefetched: true,
            chartPoints: [],
            forceEmpty: true,
          }
        });

        return;
      }

      const points = filterUniquePoints(
        [
          ...response.data.result.points,
          ...delayedPointsBuffer.current.filter((point) => point["time"] > lastPrefetchPoint["time"])
        ],
        "time"
      );

      lastDataTime.current = points[points.length-1]['time'];

      dispatch({
        type: "SET_CHART_DATA_UPDATED",
        payload: {
          windowSize,
          isPrefetched: true,
          chartPoints: points
        }
      });
    }).catch((error) => {
      console.log(error);
    });
  }, [
    task.objectId,
    timeSeriesCollection
  ]);

  const handlePresetChange = useCallback((selectedTab) => {
    delayedPointsBuffer.current = [];

    let newChartHeight = CHANNEL_HEIGHT_DOM * getTabChannels(task, selectedTab, 0).length;
    if (newChartHeight <= 300) newChartHeight = 300;

    dispatch({
      type: 'PRESET_CHANGE',
      payload: {
        isPrefetched: false,
        isLoading: true,
        activeTab: selectedTab,
        channelsOffset: 0,
        chartHeight: {
          previous: state.activeTab ? CHANNEL_HEIGHT_DOM * getTabChannels(task, state.activeTab, state.channelsOffset).length : 0,
          current: newChartHeight,
        }
      },
    });

    const channels = getTabChannels(task, selectedTab, 0);
    const windowSize = selectedTab.samplesPerSecond * selectedTab.durationInSeconds;
    setActiveChartPreset(selectedTab);

    if (state.isStreaming) {
      switchDataOnline(selectedTab.samplesPerSecond, windowSize, channels);
      return;
    }

    switchDataOffline(selectedTab.samplesPerSecond, windowSize, channels);
  }, [
    setActiveChartPreset,
    state.activeTab,
    state.channelsOffset,
    state.isStreaming,
    switchDataOffline,
    switchDataOnline,
    task,
  ]);

  const handleChannelVisibilityChange = useCallback((selectedVisibleChannels=null) => {

    if (selectedVisibleChannels) {
      dispatch({
        type: 'CHANNEL_VISIBILITY_CHANGE',
        payload: {visibleChannels: selectedVisibleChannels}
      });

      setVisibleChannels(selectedVisibleChannels);
    } else {
      selectedVisibleChannels = stateRef.current.visibleChannels;
    }

    let qualityEnabled = stateRef.current.showQuality;

    for (const [key, value] of Object.entries(selectedVisibleChannels)) {
      let channelSeries = chartRef.current.series.get(key)
      if (channelSeries?.getVisible() !== value) {
        channelSeries?.setVisible(value)
      }

      let qualitySeries = chartRef.current.series.get(key + "_quality")
      if (qualityEnabled && qualitySeries?.getVisible() == true && value == false) {
        qualitySeries?.setVisible(false)
      } else if (qualityEnabled && qualitySeries?.getVisible() == false && value == true) {
        qualitySeries?.setVisible(true)
      }
    }
  }, [
    setVisibleChannels,
    task,
  ]);

  const addData = async (data) => {
    const channels = Object.keys(data).filter((key) => !METADATA_KEYS.includes(key));

    if (state.isEmpty) {
      dispatch({type: "SET_IS_EMPTY", payload: {isEmpty: false}});
    }

    if (!chartRef.current.chart || stateRef.current.isLoading) {
      if (!stateRef.current.isPrefetched) {
        const channelsCount = channels.length;

        if (!ignoreChannelMismatch && chartRef.current.series.size !== channelsCount) {
          delayedPointsBuffer.current.push(data);
          return;
        }
      }

      return;
    }

    if (data["time"] <= lastDataTime.current) {
      console.warn(`
        Received data is out of order and will be ignored - time 
        ${data["time"]} is less than or equal to the time of the last
        rendered data point ${lastDataTime.current}.
      `);
      return;
    }

    const channelsCount = channels.length;

    if (!ignoreChannelMismatch && chartRef.current.series.size !== channelsCount) {
      console.log("Mismatch (seriesSize, channelsCount): ", chartRef.current.series.size, channelsCount);
      return;
    }

    if (!stateRef.current.isAxisAligned) {
      chartRef.current.chart.getDefaultAxisX().setInterval({
        start: data["time"] - stateRef.current.activeTab.durationInSeconds * 1000,
        end: data["time"],
        animate: false,
        stopAxisAfter: false
      });

      dispatch({type: "SET_IS_AXIS_ALIGNED", payload: {isAxisAligned: true}});
    }

    channels.forEach((channelKey, index) => {
      const series = chartRef.current.series.get(channelKey);

      if (!series)
        return;

      const yAxisOffset = ((channelsCount - 1) - index) * CHANNEL_INTERVAL_Y;

      series.add({
        x: data["time"],
        y: (data[channelKey] * TimeSeriesScaleByUnit[task.units]) + yAxisOffset,
      });
    });

    lastDataTime.current = data["time"];
  }

  // Update legend bubbles with colors based on quality
  const updateLegendQuality = (data) => {
    if (state.isStreaming && chartRef.current.qualityLegend && !state.isEmpty) {
      const entries = chartRef.current.qualityLegend.entries;
      const channels = chartRef.current.legend.entries;

      for (let i = 0; i < entries.length; i += 1) {
        const entryToColor = entries[i].entry;

        const qualityKey = `quality_${channels[i].entry.getText()}`;
        const qualityValue = data?.[qualityKey];
        const color = getSignalColor(qualityValue);

        entryToColor.setButtonOnFillStyle(new SolidFill({ color }))
        .setVisible(true);
      }
    }
  }

  // Determine color based on quality value
  const getSignalColor = (value) => {
      if (value < 0.1) {
        return ColorRGBA(255, 0, 0); // Red
      } else if (value < 0.5) {
        return ColorRGBA(255, 255, 0); // Yellow
      } else {
        return ColorRGBA(0, 255, 0); // Green
      }
  }

  const changeQualityVisibility = ((series, visible) => {
    let qualityEnabled = stateRef.current.showQuality;
    let qualitySeries = chartRef.current.series.get(series.getName() + "_quality")
    
    if (qualityEnabled && qualitySeries.getVisible() == true && visible == false) {
      qualitySeries.setVisible(false)
    } else if (qualityEnabled && qualitySeries.getVisible() == false && visible == true) {
      qualitySeries.setVisible(true)
    }
  })

  const changeVisibleChannels = (series, visible) => {
    const seriesName = series.getName();
    
    const newChannels = { ...stateRef.current.visibleChannels, [seriesName]: visible };

    handleChannelVisibilityChange(newChannels)
  };

  const maybeAddSeries = (seriesName, channelIndex, channelsCount) => {
    if (chartRef.current.series.has(seriesName))
      return { 
        channelSeries: chartRef.current.series.get(seriesName), 
        pointSeries: chartRef.current.series.get(seriesName + "_quality"),
      }

    const chart = chartRef.current.chart;

    const channelSeries = chart.addLineSeries({
      dataPattern: "ProgressiveX",
      regularProgressiveStep: true,
    }).setCursorResultTableFormatter((tableBuilder, series, _x, _y, dataPoint) =>
      tableBuilder
        .addRow(series.getName())
        .addRow('X', '', moment.utc(Math.round(dataPoint.x)).format("HH:mm:ss.SSS"))
        .addRow('Y', '', (dataPoint.y - ((channelsCount - 1) - channelIndex) * CHANNEL_INTERVAL_Y).toFixed(1))
    ).setName(seriesName)
      .setStrokeStyle(style => style.setThickness(1))
      .setMouseInteractions(false)
      .setDataCleaning({minDataPointCount: 5000});

    channelSeries.onVisibleStateChanged(changeQualityVisibility)
    channelSeries.onVisibleStateChanged(changeVisibleChannels)

    const axisTickY = chart
      .getDefaultAxisY()
      .addCustomTick(UIElementBuilders.AxisTick)

    axisTickY.setValue((channelsCount - (1 + channelIndex)) * CHANNEL_INTERVAL_Y)
      .setTextFormatter(() => seriesName)
      .setGridStrokeStyle(
        new SolidLine({
          thickness: 1,
          fillStyle: new SolidFill({color: ColorRGBA(255, 255, 255, 60)}),
        }),
      );

    addSeries(seriesName, channelSeries)
    chartRef.current.yAxisTicks.set(seriesName, axisTickY);

    const fillStyle = new IndividualPointFill({ color: ColorRGBA(255, 0, 0) })
    let pointSeries = chart.addPointSeries({ pointShape: PointShape.Circle })
        .setName(seriesName)
        .setVisible(stateRef.current.showQuality)
        .setMouseInteractions(false)
        .setPointFillStyle(fillStyle)
        .setIndividualPointSizeEnabled(true)
        .setIndividualPointRotationEnabled(true)
        .setIndividualPointValueEnabled(true)
        .setDataCleaning({minDataPointCount: 5000})

    if (stateRef.current.isStreaming) {
      pointSeries.setVisible(true)
      chartRef.current.qualityLegend.add(pointSeries);
    }

    chartRef.current.legend.add(channelSeries);
    return { channelSeries, pointSeries };
  }

  const processChannelsPageChangeOffline = useCallback(() => {
    let newChartHeight = CHANNEL_HEIGHT_DOM * getTabChannels(task, activeChartPreset, state.channelsOffset).length;
    if (newChartHeight <= 300) newChartHeight = 300;

    dispatch({
      type: 'SET_CHART_HEIGHT',
      payload: {
        chartHeight: {
          previous: state.chartHeight.current,
          current: newChartHeight
        }
      }
    });

    const page = state.page.active;
    const channels = getTabChannels(task, state.activeTab, state.channelsOffset);
    const queryParams = new URLSearchParams({
      sampleRate: state.activeTab.samplesPerSecond,
      windowSize: state.windowSize,
      seriesCollection: timeSeriesCollection,
      seriesSampleRate: timeSeriesSampleRate,
    });

    const url = `${process.env.REACT_APP_SERVER_URL}/task/${task.objectId}/data/${page}?${queryParams.toString()}&channels[]=${channels.join("&channels[]=")}`;
    dispatch({type: "SET_IS_LOADING", payload: {isLoading: true}});
    clearTimeout(state.page.timeoutId);

    const timeoutId = setTimeout(async () => {
      let points = [];
      const cacheKey = `${page}.${state.activeTab.samplesPerSecond}.${state.activeTab.durationInSeconds}.${channels.join("-")}`;

      if (DATA_WINDOWS_CACHE.has(cacheKey)) {
        points = DATA_WINDOWS_CACHE.get(cacheKey);
      } else {
        const {data: {result: {points: newPoints}}} = await axios.get(url);
        DATA_WINDOWS_CACHE.set(cacheKey, newPoints);
        points = newPoints;
      }

      dispatch({
        type: "SET_CHART_DATA_UPDATED",
        payload: {
          chartPoints: points,
          isPrefetched: state.isPrefetched,
        },
      });
    }, PAGE_CHANGE_DELAY);

    dispatch({type: "SET_PAGE_CHANGE_TIMEOUT_ID", payload: {pageChangeTimeoutId: timeoutId}});
  }, [
    task,
    state.chartHeight,
    state.activeTab,
    state.channelsOffset,
    state.page.active,
    state.page.timeoutId,
    state.windowSize,
    state.isPrefetched,
    timeSeriesCollection,
    timeSeriesSampleRate,
    activeChartPreset,
  ]);

  const processChannelsPageChangeOnline = useCallback(() => {
    delayedPointsBuffer.current = [];

    let newChartHeight = state.activeTab ? CHANNEL_HEIGHT_DOM * getTabChannels(task, state.activeTab, state.prevChannelsOffset).length : 0;
    if (0 >= newChartHeight <= 300) newChartHeight = 300;

    dispatch({
      type: "PAGE_CHANGE_ONLINE",
      payload: {
        isLoading: true,
        isPrefetched: false,
        chartHeight: {
          previous: state.activeTab ? CHANNEL_HEIGHT_DOM * getTabChannels(task, state.activeTab, state.prevChannelsOffset).length : 0,
          current: CHANNEL_HEIGHT_DOM * getTabChannels(task, state.activeTab, state.channelsOffset).length
        }
      }
    });

    const channels = getTabChannels(task, state.activeTab, state.channelsOffset);
    const windowSize = state.activeTab.samplesPerSecond * state.activeTab.durationInSeconds;

    if (state.isStreaming) {
      switchDataOnline(state.activeTab.samplesPerSecond, windowSize, channels);
      return;
    }
  }, [
    state.activeTab,
    state.channelsOffset,
    state.isStreaming,
    state.prevChannelsOffset,
    switchDataOnline,
    task
  ]);

  const processChannelsPageChange = useCallback(() => {
    if (state.isStreaming) {
      processChannelsPageChangeOnline();
      return;
    }

    processChannelsPageChangeOffline();
  }, [
    processChannelsPageChangeOffline,
    processChannelsPageChangeOnline,
    state.isStreaming
  ]);

  const processPageChange = (page) => {
    if (page === state.page.active) {
      return;
    }

    dispatch({
      type: "SET_CHART_HEIGHT",
      payload: {
        chartHeight: {
          previous: state.chartHeight.current,
        }
      }
    });

    const channels = getTabChannels(task, state.activeTab, state.channelsOffset);
    const queryParams = new URLSearchParams({
      sampleRate: state.activeTab.samplesPerSecond,
      windowSize: state.windowSize,
      seriesCollection: timeSeriesCollection,
      seriesSampleRate: timeSeriesSampleRate,
    });

    const url = `${process.env.REACT_APP_SERVER_URL}/task/${task.objectId}/data/${page}?${queryParams.toString()}&channels[]=${channels.join("&channels[]=")}`;
    dispatch({type: "SET_IS_LOADING", payload: {isLoading: true}});
    clearTimeout(state.page.timeoutId);

    const timeoutId = setTimeout(async () => {
      let points = [];
      const cacheKey = `${task.objectId}.${page}.${state.activeTab.samplesPerSecond}.${state.activeTab.durationInSeconds}.${channels.join("-")}`;

      if (DATA_WINDOWS_CACHE.has(cacheKey)) {
        points = DATA_WINDOWS_CACHE.get(cacheKey);
      } else {
        const {data: {result: {points: newPoints}}} = await axios.get(url);
        DATA_WINDOWS_CACHE.set(cacheKey, newPoints);
        points = newPoints;
      }

      dispatch({
        type: "SET_CHART_DATA_UPDATED",
        payload: {chartPoints: points, isPrefetched: state.isPrefetched},
      });
    }, PAGE_CHANGE_DELAY);

    dispatch({type: "SET_PAGE_CHANGE_TIMEOUT_ID", payload: {pageChangeTimeoutId: timeoutId}});
    dispatch({type: "SET_ACTIVE_PAGE", payload: {activePage: page}});
  }

  const disposeChart = () => {
    chartRef.current.legend?.dispose();
    chartRef.current.qualityLegend?.dispose();
    chartRef.current.channelsPageSwitchSlot?.dispose();
    chartRef.current.latencyLabelTextBox?.dispose();
    chartRef.current.latencyLabelSlot?.dispose();
    chartRef.current.buttonQuality?.dispose();
    chartRef.current.buttonReset?.dispose();

    // ? Causes WebGL context to be lost, which causes
    // ? problems rendering other charts.
    // chartRef.current.chart?.dispose();

    chartRef.current = {
      chart: null,
      legend: null,
      channelsPageSwitchSlot: null,
      latencyLabelSlot: null,
      latencyLabelTextBox: null,
      series: new Map(),
      yAxisTicks: new Map(),
    };
  }

  const setupChart = useCallback(() => {
    if (chartRef.current.chart) {
      return;
    }

    chartRef.current.chart = createChart(id);
    const xAxis = chartRef.current.chart.getDefaultAxisX();
    const yAxis = chartRef.current.chart.getDefaultAxisY();

    const resetYAxis = (axis, event=null) => {
      const channels = getTabChannels(task, state.activeTab, state.channelsOffset);
      const channelsCount = channels.length;
      const startIntervalY = -CHANNEL_INTERVAL_Y / 2 - 100;
      const endIntervalY = channelsCount * CHANNEL_INTERVAL_Y;
      const intervalDeltaY = endIntervalY - startIntervalY;
  
      chartSettingsRef.current.interval.y = {
        start: startIntervalY,
        end: endIntervalY,
        deltaMin: intervalDeltaY - intervalDeltaY * INTERVAL_DELTA_MULTIPLIER,
        deltaMax: intervalDeltaY + intervalDeltaY * INTERVAL_DELTA_MULTIPLIER
      };
  
      axis.setInterval({
        start: startIntervalY,
        end: endIntervalY,
        stopAxisAfter: true,
      });
      
      if(event) {
        event.preventDefault()
        event.stopPropagation()
      }
    }

    const resetXAxis = (axis, event=null) => {
      if (stateRef.current.chartPoints.length > 0) {
        let lastPoint = 0
        if (stateRef.current.isStreaming) {
          lastPoint = chartRef.current.series.values().next().value.getLastPoint()["x"];
        } else {
          lastPoint = stateRef.current.chartPoints[stateRef.current.chartPoints.length - 1]["time"];
        }

        axis.setInterval({
          start: stateRef.current.chartPoints[0]["time"],
          end: lastPoint,
          animate: false,
          stopAxisAfter: false
        });
      }
    }

    // Add LCJS user interface button for resetting zoom.
    const buttonReset = chartRef.current.buttonReset || chartRef.current.chart
      .addUIElement()
      .setVisible(false)
      .setText('Reset axis')
      .setPosition({ x: stateRef.current.isStreaming ? 88 : 90.5, y: stateRef.current.isStreaming ? 99 : 90 })
      .setOrigin(UIOrigins.RightTop)
      .setMargin({ left: 4, bottom: 4 })
      .setDraggingMode(UIDraggingModes.notDraggable)
    
      
    buttonReset.onMouseClick((_) => {
      resetYAxis(yAxis);
      resetXAxis(xAxis);
      buttonReset.setVisible(false);
      dispatch({type: "SET_CUSTOM_ZOOM", payload: {customZoom: false}});
    })

    chartRef.current.buttonReset = buttonReset;

    const buttonQuality = chartRef.current.buttonQuality || chartRef.current.chart
      .addUIElement()
      .setText('Show qualities')
      .setVisible(false)
      .setOrigin(UIOrigins.RightTop)
      .setPosition({ x: 90.5, y: 99 })
      .setMargin({ left: 4, bottom: 4 })
      .setDraggingMode(UIDraggingModes.notDraggable)

    buttonQuality.onMouseClick((_) => {
        const entries = chartRef.current.chart.getSeries();
    
        for (let i = 1; i < entries.length; i += 2) {
            let insightEntry = entries[i - 1];
            let qualityEntry = entries[i];
    
            if (qualityEntry instanceof PointSeries) { 
                if (insightEntry.getVisible()) {
                  qualityEntry.setVisible(stateRef.current.showQuality ? false : true);
                } else {
                  qualityEntry.setVisible(false)
                }
            }
        }
    
        buttonQuality.setText(buttonQuality.getText() === 'Hide qualities' ? 'Show qualities' : 'Hide qualities');
        dispatch({ type: "SET_SHOW_QUALITY", payload: { showQuality: stateRef.current.showQuality ? false : true } });
    });

    chartRef.current.buttonQuality = buttonQuality;
     
    const enableCustomZoom = () => {
      buttonReset.setVisible(true);
      dispatch({type: "SET_CUSTOM_ZOOM", payload: {customZoom: true}});
    }

    chartRef.current.chart.onSeriesBackgroundMouseDrag(enableCustomZoom);
    
    yAxis.onAxisInteractionAreaMouseWheel(enableCustomZoom);
    yAxis.onAxisInteractionAreaMouseDragStop(enableCustomZoom);
    yAxis.onAxisInteractionAreaMouseDoubleClick(resetYAxis);

    xAxis.onAxisInteractionAreaMouseWheel(enableCustomZoom);
    xAxis.onAxisInteractionAreaMouseDragStop(enableCustomZoom);
    xAxis.onAxisInteractionAreaMouseDoubleClick(resetXAxis);

    yAxis.onIntervalChange((axis, start, end) => {
      if (stateRef.current.chartPoints.length > 0) {
        const delta = end - start;
        const {deltaMin, deltaMax, start: lastStart, end: lastEnd} = chartSettingsRef.current.interval.y;
        
        if ((delta > deltaMax || delta < deltaMin) && deltaMin !== 0 && deltaMax !== 0) {
          axis.setInterval({
            start: lastStart,
            end: lastEnd,
            stopAxisAfter: true
          });
          
          return;
        }
        
        chartSettingsRef.current.interval.y.start = start;
        chartSettingsRef.current.interval.y.end = end;
      }
    })
    

    xAxis.onIntervalChange((axis, start, end) => {
        if (stateRef.current.chartPoints.length > 0) {
          chartSettingsRef.current.interval.x.start = start;
          chartSettingsRef.current.interval.x.end = end;
        }
    })
  }, [id]);

  useEffect(() => {
    setupChart();
    return () => disposeChart();
  }, [setupChart]);

  useEffect(() => {
    dispatch({type: "SET_IS_STREAMING", payload: {isStreaming: isTaskStreaming(task.state)}});
  }, [task.state]);

  useEffect(() => {
    speedTestStateRef.current = {isSpeedTestDone, isSpeedTestRunning};
  }, [isSpeedTestDone, isSpeedTestRunning]);

  useEffect(() => {
    if (!socket || !state.isRedrawPending) {
      return;
    }

    if (state.isRedrawPending === true) {
      drawChart();
    }

    requestAnimationFrame(() => {
      dispatch({type: "SET_IS_REDRAW_PENDING", payload: {isRedrawPending: false}});
      dispatch({type: "SET_IS_LOADING", payload: {isLoading: false}});
    });
  }, [
    state.isRedrawPending,
    drawChart,
    socket,
  ]);

  useEffect(() => {
    if (!socket || !state.isRefetchPending) {
      return;
    }

    processChannelsPageChange();
    dispatch({type: "SET_IS_REFETCH_PENDING", payload: {isRefetchPending: false}});
  }, [
    state.isRefetchPending,
    processChannelsPageChange,
    socket,
  ]);

  /**
   * This is only required on initial render to pre-select previously selected tab
   * or select a default one. It should not be triggered by tab switches initiated by
   * the user.
   */
  useEffect(() => {
    if (!socket) {
      return;
    }

    if (!activeChartPreset
      || (activeChartPreset.id.startsWith("on-") && !state.isStreaming)
      || (activeChartPreset.id.startsWith("off-") && state.isStreaming)
    ) {
      const preset = state.isStreaming ? TIME_SERIES_PRESETS_ONLINE[0] : TIME_SERIES_PRESETS_OFFLINE[0];
      handlePresetChange(preset);
      return;
    } else {
      handlePresetChange(activeChartPreset);
    }
  }, [state.isStreaming]);

  useEffect(() => {
    if (!socket) {
      return;
    }

    if (!visibleChannels || visibleChannels == {}) {
      let defaultVisibleChannels = getVisibleChannels();

      handleChannelVisibilityChange(defaultVisibleChannels);
    } else {
      handleChannelVisibilityChange(visibleChannels);
    }

  }, [state.isStreaming]);

  useEffect(() => {
    stateRef.current = state;
  }, [state]);

  useEffect(() => {
    if (!chartRef.current || !isSpeedTestDone || !chartRef.current.latencyLabelTextBox) {
      return;
    }

    chartRef.current.latencyLabelTextBox.setText(`${connectionLatency} ms`);
  }, [isSpeedTestDone, chartRef.current.latencyLabelTextBox, connectionLatency])

  return (
    children({
      id,
      task,
      state,
      socket,
      addData,
      updateLegendQuality,
      dispatch,
      getTaskDetails,
      processPageChange,
      handlePresetChange,
      containerHeight: state.isLoading && !state.isRedrawPending ? state.chartHeight.previous : state.chartHeight.current,
      legendData: {
        size: legendSize,
        pages: state.activeTab.divider,
        currentPage: state.channelsOffset + 1,
        paginatorVisible: getTabChannels(task, {divider: 1}).length > 1 ? state.activeTab.divider > 1 : false,
        handlePagePrev: () => dispatch({type: "CHANNELS_OFFSET_PREV"}),
        handlePageNext: () => dispatch({type: "CHANNELS_OFFSET_NEXT"}),
      },
      chart: chartRef.current.chart,
      channelSeries: chartRef.current.series
    })
  );
}

function mapDispatchToProps(dispatch) {
  return {
    requestSpeedTest: (id) => dispatch(requestSpeedTest(id))
  };
}

export default connect(null, mapDispatchToProps)(ChannelsChart);
