import axios from "axios";
import { useState } from "react";
import IconButton from "../common/iconButton";
import { TOKEN_KEY } from "../../store/constants";
import { Modal, Button, Container, Row, Col, Spinner } from "react-bootstrap";

const DownloadWidgetModal = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);

  const fetchAndDownload = async () => {
      try {
          setLoading(true);

          const releaseName = process.env.REACT_APP_ENV === "production" ? "master" : "staging";

          const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/download/request`, {
            name: releaseName
          }, {
              headers: {
                  Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
              },
              responseType: 'blob',
          });
  
          const fileURL = window.URL.createObjectURL(new Blob([response.data]));

          const link = document.createElement('a');
          link.href = fileURL;

          // const contentDisposition = response.headers['content-disposition'];
          // const matches = contentDisposition.match(/filename="([^"]+)"/);
          // const fileName = matches && matches[1] ? matches[1] : "InnoBrain-Setup.zip";

          const fileName = "InnoBrain-Setup.zip"

          link.setAttribute('download', fileName);

          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
      } catch (error) {
          console.error("Error fetching download link:", error);
      } finally {
          setLoading(false);
      }
  };
  

  return (
    <>
      <IconButton
        title="Download Widget"
        icon="download"
        size="large"
        onClick={() => setShow(true)}
      />
      <Modal
        centered
        show={show}
        onHide={() => setShow(false)}
        className="text-center modal"
      >
        <Modal.Header className="p-0" closeButton closeVariant="white">
          <Modal.Title className="text-white text-medium">Innobrain Desktop Widget</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0 pt-3">
          <p className="text-white opacity-75 text-start">
            1: Click "Download" to proceed.
            <br />
            2: Wait for the download to complete.
            <br />
            3: Run the downloaded installer file.
            <br />
            4: Follow instructions within the installer.
            <br />
            <br />
          </p>
          <Container fluid className="p-0 mt-3">
            <Row>
              <Col xs={6}>
                <Button className="w-100" variant="secondary" onClick={() => setShow(false)}>
                  Close
                </Button>
              </Col>
              <Col xs={6}>
                <Button
                  className="w-100"
                  onClick={loading ? null : fetchAndDownload}
                  disabled={loading}
                >
                  {loading ? (
                    <>
                      <Spinner animation="border" size="sm" /> Loading...
                    </>
                  ) : (
                    "Download"
                  )}
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DownloadWidgetModal;
